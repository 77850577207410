import { IMember } from "../interfaces";
import { intersection } from "lodash";
import { AuthorityGroup, AuthorityRole, IAuthorityRole } from "../../acl/interfaces";

import format from 'date-fns/format';
import { parseISO } from "date-fns";
export class Member {

    private _data: IMember | null = null;
    private _checked: boolean = false;

    constructor(data: IMember | null = null) {
        this._data = data;
    }

    get id(): string {
        return this._data ? this._data.id : format(new Date(), 'X');
    }

    get checked(): boolean {
        return this._checked;
    }

    set checked(value: boolean) {
        this._checked = value;
    }

    get data(): IMember | null {
        return this._data;
    }

    get isLoggedIn(): boolean {
        return this._data ? true : false;
    }

    get fullname(): string {
        if (!this._data) {
            return '';
        }

        return `${this.lastname}  ${this.firstname} `;
    }

    get firstname(): string {
        if (!this._data) {
            return '';
        }

        return this._data.firstName;
    }

    get lastname(): string {
        if (!this._data) {
            return '';
        }

        return this._data.lastName;
    }

    get title(): string {
        let title = '';
        if (!this._data) {
            return title;
        }


        switch (this._data.gender) {
            case 'male':
                title = '先生';
                break;
            case 'female':
                title = '女士';
                break;
            default:
                break;
        }

        return title;
    }

    get profile(): IMember | null {
        return this._data;
    }

    get email(): string | null {
        return this._data?.email ?? null;
    }


    get phoneNumber(): string | null {
        return this._data?.phoneNumber ?? null;
    }


    get company(): string | null {
        return this._data?.company ?? null;
    }



    hasRole = (rolesGroups: string[]) => {
        const roleCount = intersection(rolesGroups, this.roleCode);
        return roleCount.length > 0 ? true : false;
    }

    hasGroup = (groups: string[]) => {
        const count = intersection(groups, this.groupCode);
        return count.length > 0 ? true : false;
    }

    get roleCode(): AuthorityRole[] {
        if (!this._data) {
            return [];
        }

        return this.roles.map(role => role.code);
    }


    get groupCode(): AuthorityGroup[] {
        if (!this._data) {
            return [];
        }

        return this.groups.map(group => group.code);
    }

    get roles(): IAuthorityRole[] {
        if (!this._data) {
            return [];
        }

        return this._data.roles;
    }

    get groups(): any[] {
        if (!this._data) {
            return [];
        }

        return this._data.groups;
    }

    get isAdmin(): boolean {
        return this._data?.isAdmin ? true : false;
    }

    get isSubscribed(): boolean {
        return this._data?.isSubscribed ? true : false;
    }


    get createdAt(): Date | null {

        return this._data?.createdAt ? parseISO(this._data.createdAt) : null;
    }

    get updatedAt(): Date | null {
        return this._data?.updatedAt ? parseISO(this._data.updatedAt) : null;
    }
}
