export * from './shop.namespace';

export const GLOBAL_NAMESPACE = 'today.';

export const APP_INFO_NAMESPACE = 'appInfo.';
export const APP_LOCALE = APP_INFO_NAMESPACE + 'locale';
export const INIT_FLAG = APP_INFO_NAMESPACE + 'initFlag';
export const START_USING_DATE = APP_INFO_NAMESPACE + 'startUsingDate';
export const LIVEMODE = APP_INFO_NAMESPACE + 'livemode';

export const USER_INFO_NAMESPACE = 'userInfo.';
export const USERNAME = USER_INFO_NAMESPACE + 'username';
export const AVATAR_CODE = USER_INFO_NAMESPACE + 'avatarCode';
export const USER_SPACE_UNIT = APP_INFO_NAMESPACE + 'unit';

export const USER_ACCOUNT_NAMESPACE = USER_INFO_NAMESPACE + 'userAccount.';
export const USER_ACCOUNT_ACCESS_TOKEN = USER_ACCOUNT_NAMESPACE + 'accessToken';
export const USER_ACCOUNT_ACCESS_TOKEN_EXPIRY = USER_ACCOUNT_NAMESPACE + 'accessTokenExpiry';
export const USER_AUTH_REFRESH_TOKEN = USER_ACCOUNT_NAMESPACE + 'refreshToken';

export const USER_ACCOUNT_NOTIFICATION = USER_ACCOUNT_NAMESPACE + 'notification.';
export const USER_ACCOUNT_NOTIFICATION_UNREAD = USER_ACCOUNT_NOTIFICATION + 'unread';
export const USER_ACCOUNT_NOTIFICATION_READ = USER_ACCOUNT_NOTIFICATION + 'read';

export const TODO_NAMESPACE = GLOBAL_NAMESPACE + 'todo.';
export const TODOS = TODO_NAMESPACE + 'todos';

export const LIST_NAMESPACE = GLOBAL_NAMESPACE + 'list.';
export const LISTS = LIST_NAMESPACE + 'lists';

export const SUMMARY_NAMESPACE = GLOBAL_NAMESPACE + 'summary.';
export const LAST_SUMMARY_DATE = SUMMARY_NAMESPACE + 'lastSummaryDate';
export const SUMMARIES = SUMMARY_NAMESPACE + 'summaries';
export const CART_TOKEN = USER_ACCOUNT_NAMESPACE + 'cartToken';

export const ORDER_NAMESPACE = 'order.';
export const ORDER_QUOTE = ORDER_NAMESPACE + 'quote.';
export const ORDER_QUOTE_LISTS = ORDER_QUOTE + 'lists';

export const ORDER_SHIPMENT = ORDER_NAMESPACE + 'shipment.';
export const ORDER_SHIPMENT_UNCREATE = ORDER_SHIPMENT + 'uncreate';


