import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { SUPPORTED_LOCALE_OPTIONS } from '../../constants/supported.locale.constant';
import { SupportedLocaleCode } from '../../interfaces';
import { TranslateHelperService } from '../../translate-helper.service';

@Component({
  selector: 'locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocaleSelectorComponent implements OnInit {
  localeId$ = this.translateHelper.currentLocale$;

  list = SUPPORTED_LOCALE_OPTIONS.filter(item => ![SupportedLocaleCode.zh.toString()].includes(item.label));
  constructor(
    private translateHelper: TranslateHelperService,
    private dfnsConfig: DateFnsConfigurationService
  ) {

  }

  ngOnInit(): void {
  }

  setLang(lang: string): void {
    this.translateHelper.setLang(lang);
    this.translateHelper.setDateFnsLocale(this.dfnsConfig);
  }

  trackByFn(i: number): number {
    return i;
  }

}
