import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { StorageHelperService } from "@app/helpers/storage";
import { CART_TOKEN } from "@app/helpers/storage/constants";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { HttpHelperService } from "@app/helpers/http";
import { environment } from "@environments/environment";
import { IHttpResponse } from "@app/helpers/http/interfaces";
import { ShopApiService } from "../shop";
import { ICart, ICartAddItemDTO, ICartUpdateItemDTO } from "./interfaces";
import { emptyCart } from "./constants";

@Injectable({
  providedIn: "root",
})
export class CartApiService {
  private coreEndPoint = environment.coreEndPoint;

  #cartToken$ = new BehaviorSubject<string | null>(null);
  cartToken$ = this.#cartToken$.asObservable();

  #cart$ = new BehaviorSubject<ICart>(emptyCart);
  cart$ = this.#cart$.asObservable();
  #identityChecked$ = new BehaviorSubject<boolean>(false);
  identityChecked$ = this.#identityChecked$.asObservable();
  #serviceChecked$ = new BehaviorSubject<boolean>(false);
  serviceChecked$ = this.#serviceChecked$.asObservable();
  readonly shop$ = this.shopService.shop$;

  constructor(
    private storageService: StorageHelperService,
    private http: HttpHelperService,
    private shopService: ShopApiService
  ) {}

  set token(value: string | null) {
    this.#cartToken$.next(value);
    this.saveToken(value).pipe(take(1)).subscribe();
  }

  set cart(value: ICart) {
    this.#cart$.next(value);
  }

  set identityChecked(value: boolean) {
    this.#identityChecked$.next(value);
  }

  set serviceChecked(value: boolean) {
    this.#serviceChecked$.next(value);
  }

  init(): Observable<string> {
    return this.initTokenFromLocal().pipe(
      switchMap((localToken) => {
        if (localToken) {
          return of(localToken);
        } else {
          return this.initTokenFromServer<{
            cart: ICart;
            cartToken: string;
          }>().pipe(map((res) => res.data?.cartToken));
        }
      })
    );
  }

  initTokenFromLocal(): Observable<string> {
    return this.storageService.get(CART_TOKEN).pipe(
      take(1),
      tap((token) => {
        this.token = token;
      })
    );
  }

  initTokenFromServer<T>(): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/cart`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res) => {
        if (res.data) {
          const token = res.data?.cartToken ?? null;
          this.token = token;
          const cart = res.data?.cart ?? emptyCart;
          this.cart = cart;
        }
      })
    );
  }

  retrieveCartFromServer<T>(): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/shop/${this.shop$()?.id ?? 0}/cart`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res) => {
        if (res.data) {
          const token = res.data?.cartToken ?? null;
          this.token = token;
          const cart = res.data?.cart ?? emptyCart;
          this.cart = cart;
        }
      })
    );
    // return this.shopService.shop$.pipe(
    //   switchMap((shop) => {
    //     const path = `${this.coreEndPoint}/api/shop/${shop?.id}/cart`;
    //     return this.http.get(path).pipe(
    //       catchError(this.http.catch()),
    //       tap(res => {
    //         if (res.data) {
    //           const token = res.data?.cartToken ?? null;
    //           this.token = token;
    //           const cart = res.data?.cart ?? emptyCart;
    //           this.cart = cart;
    //         }
    //       })
    //     );
    //   })
    // )
  }

  addItem(data: ICartAddItemDTO): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/shop/${this.shop$()?.id ?? 0}/cart`;
    return this.http.post(path, data).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const cart = data?.cart ?? null;
          const cartToken = data?.cartToken ?? null;

          if (cart) {
            this.cart = cart;
          }

          if (cartToken) {
            this.token = cartToken;
          }
        }
      })
    );

    // return this.shopService.shop$.pipe(
    //   switchMap((shop) => {
    //     const path = `${this.coreEndPoint}/api/shop/${shop?.id}/cart`;
    //     return this.http.post(path, data).pipe(
    //       catchError(this.http.catch()),
    //       tap((res: IHttpResponse) => {
    //         if (res.success) {
    //           const { data } = res;
    //           const cart = data?.cart ?? null;
    //           const cartToken = data?.cartToken ?? null;

    //           if (cart) {
    //             this.cart = cart;
    //           }

    //           if (cartToken) {
    //             this.token = cartToken;
    //           }

    //         }

    //       })
    //     );
    //   })
    // )
  }

  updateItem(
    data: ICartUpdateItemDTO,
    token: string
  ): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/shop/${
      this.shop$()?.id ?? 0
    }/cart/${token}`;
    return this.http.put(path, data).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const cart = data?.cart ?? null;
          const cartToken = data?.cartToken ?? null;

          if (cart) {
            this.cart = cart;
          }

          if (cartToken) {
            this.token = cartToken;
          }
        }
      })
    );
    // return this.shopService.shop$.pipe(
    //   switchMap((shop) => {
    //     const path = `${this.coreEndPoint}/api/shop/${shop?.id}/cart/${token}`;
    //     return this.http.put(path, data).pipe(
    //       catchError(this.http.catch()),
    //       tap((res: IHttpResponse) => {
    //         if (res.success) {
    //           const { data } = res;
    //           const cart = data?.cart ?? null;
    //           const cartToken = data?.cartToken ?? null;

    //           if (cart) {
    //             this.cart = cart;
    //           }

    //           if (cartToken) {
    //             this.token = cartToken;
    //           }

    //         }

    //       })
    //     );
    //   })
    // )
  }

  private saveToken(token: string | null): Observable<boolean> {
    return this.storageService.set(CART_TOKEN, token).pipe(take(1));
  }
}
