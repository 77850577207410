export enum SupportedLocaleCode {
    en = 'en',
    fr = 'fr',
    nl = 'nl',
    zh = 'zh',

}

export type SupportedLocaleCodeType = keyof typeof SupportedLocaleCode;

export type SupportedLocale = Record<SupportedLocaleCodeType, { text: SupportedLocaleCodeType, translate: string }>;