import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from 'date-fns';
import { enUS, fr, nl, zhCN } from 'date-fns/locale';
import { SupportedLocaleCode } from '../interfaces';
import { TranslateHelperService } from '../translate-helper.service';

@Pipe({
  name: 'dateFnsLocale',
  pure: true
})
export class DateFnsLocalePipe implements PipeTransform {
  constructor(
    private translate: TranslateHelperService
  ) {

  }
  transform(value: string, ...args: unknown[]): Locale {

    switch (value) {
      case SupportedLocaleCode.zh:
        return zhCN;
      case SupportedLocaleCode.en:
        return enUS;
      case SupportedLocaleCode.fr:
        return fr;
      case SupportedLocaleCode.nl:
        return nl;
      case 'nl-be':
        return nl;
      case 'zh-cn':
        return zhCN;
      default:
        return enUS;
    }

  }

}
