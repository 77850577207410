import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  en_US,
  fr_BE,
  fr_FR,
  nl_BE,
  NzI18nService,
  zh_CN,
} from "ng-zorro-antd/i18n";
import { SupportedLocaleCode, SupportedLocaleCodeType } from "./interfaces";
import { BehaviorSubject, Observable } from "rxjs";

import { APP_LOCALE } from "../storage/constants";
import { map, take, tap } from "rxjs/operators";
import { StorageHelperService } from "../storage";
import { DateFnsConfigurationService } from "ngx-date-fns";
import { enUS, fr, nl, zhCN } from "date-fns/locale";
@Injectable({
  providedIn: "root",
})
export class TranslateHelperService {
  private _currentLocale = new BehaviorSubject<SupportedLocaleCodeType>("fr");
  currentLocale$ = this._currentLocale.asObservable();

  constructor(
    private translate: TranslateService,
    private storage: StorageHelperService,
    private i18n: NzI18nService,
    public dateFnConfig: DateFnsConfigurationService
  ) {}

  init(): Observable<boolean> {
    return this.storage.get(APP_LOCALE).pipe(
      tap((res) => {
        const locale =
          (res as SupportedLocaleCodeType) ?? SupportedLocaleCode.fr;
        this, this.setLang(locale);
      }),
      map(() => true)
    );
  }

  setLang(langId: string): void {
    let locale: SupportedLocaleCodeType;

    switch (langId) {
      case SupportedLocaleCode.zh:
        this.i18n.setLocale(zh_CN);
        this.translate.use(SupportedLocaleCode.zh);
        locale = SupportedLocaleCode.zh;
        break;

      case SupportedLocaleCode.en:
        this.i18n.setLocale(en_US);
        this.translate.use(SupportedLocaleCode.en);
        locale = SupportedLocaleCode.en;
        break;

      case SupportedLocaleCode.fr:
        this.i18n.setLocale(fr_FR);
        this.translate.use(SupportedLocaleCode.fr);
        locale = SupportedLocaleCode.fr;
        break;

      case SupportedLocaleCode.nl:
        this.i18n.setLocale(nl_BE);
        this.translate.use(SupportedLocaleCode.nl);
        locale = SupportedLocaleCode.nl;
        break;

      default:
        this.i18n.setLocale(fr_BE);
        this.translate.use(SupportedLocaleCode.fr);
        locale = SupportedLocaleCode.fr;
        break;
    }

    this.currentLocale = locale;
    this.storage.set(APP_LOCALE, locale).pipe(take(1)).subscribe();
  }

  setDateFnsLocale(config: DateFnsConfigurationService): void {
    switch (this._currentLocale.value) {
      case SupportedLocaleCode.zh:
        config.setLocale(zhCN);
        break;

      case SupportedLocaleCode.en:
        config.setLocale(enUS);
        break;

      case SupportedLocaleCode.fr:
        config.setLocale(fr);
        break;

      case SupportedLocaleCode.nl:
        config.setLocale(nl);
        break;

      default:
        config.setLocale(fr);
        break;
    }
  }

  private set currentLocale(locale: SupportedLocaleCodeType) {
    this._currentLocale.next(locale);
  }
}
