import { OptionFn } from "../functions";

export const TaxTypes = {
    gst: 'gst',
    pst: 'pst',
    hst: 'hst',
    qst: 'qst',
    tax: 'tax'
} as const;

export const TaxTypeOptions = OptionFn(TaxTypes);