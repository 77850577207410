<nz-dropdown-menu #localeList="nzDropdownMenu">
  <ng-container *ngFor="let locale of list; trackBy: trackByFn">
    <li>
      <a nz-menu-item (click)="setLang(locale.label)">
        <span [translate]="locale.value"></span>
        <nz-divider nzType="vertical"></nz-divider>
        <span class="text-black">
          {{ locale.label }}
        </span>
        <ng-container *ngIf="locale.label === (localeId$ | async)">
          <nz-divider nzType="vertical"></nz-divider>
          <span nz-typography nzType="success">
            <i nz-icon nzType="check-circle"></i>
          </span>
        </ng-container>
      </a>
    </li>
  </ng-container>
</nz-dropdown-menu>

<button
  nz-button
  nzType="text"
  nz-dropdown
  [nzDropdownMenu]="localeList"
  [nzOverlayStyle]="{ background: 'white' }"
>
  <i nz-icon nzType="global"></i>
  {{ "locale.language." + (localeId$ | async) | translate }}
  <nz-divider nzType="vertical"></nz-divider>
  {{ localeId$ | async }}

  <i nz-icon nzType="down"></i>
</button>
