import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { environment } from "@environments/environment";
import { StorageHelperService } from "@app/helpers/storage";
import { StateHelperService } from "@app/helpers/state";
import { HttpHelperService } from "@app/helpers/http";

import {
  USER_ACCOUNT_ACCESS_TOKEN,
  USER_AUTH_REFRESH_TOKEN,
} from "@app/helpers/storage/constants";
import { IHttpResponse } from "@app/helpers/http/interfaces";
import { Member } from "../member/models";

@Injectable({
  providedIn: "root",
})
export class AuthCoreService {
  #accessToken$ = new BehaviorSubject<any>(null);
  public accessToken$ = this.#accessToken$.asObservable();
  #refreshToken$ = new BehaviorSubject<any>(null);
  public refreshToken$ = this.#refreshToken$.asObservable();
  #authUser$ = new BehaviorSubject<Member>(new Member());
  public authUser$ = this.#authUser$.asObservable();
  #endPoint: string;
  email$ = new BehaviorSubject<string | null>(null);
  #isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.#isLoggedIn$.asObservable();

  isLoggedIn = signal(false);

  constructor(
    private storageService: StorageHelperService,
    private stateService: StateHelperService,
    private http: HttpHelperService
  ) {
    this.#endPoint = environment.authEndPoint;
  }

  set email(value: string | null) {
    this.email$.next(value);
  }

  get email(): string | null {
    return this.email$.value;
  }

  set accessToken(value: string | null) {
    this.#accessToken$.next(value);
    this.access_token_save(value).pipe(take(1)).subscribe();
  }

  user_watch(): Observable<Member> {
    return this.authUser$;
  }

  accessTokenInit(): Observable<string> {
    return this.storageService.get(USER_ACCOUNT_ACCESS_TOKEN).pipe(
      take(1),
      tap((token) => (this.accessToken = token))
    );
  }

  // auth_user_get(): Observable<Member> {
  //   const path = `member/profile`;
  //   return this.http.get(path).pipe(
  //     catchError(this.http.catch()),
  //     tap(this.http.error_msg_handler()),
  //     map((res: IHttpResponse) => {
  //       const account = new Member(res?.data);
  //       this.authUser = account;
  //       return account;
  //     }),
  //   );
  // }

  // verify_email_send() {
  //   const path = `account/auth/verify/email/send`;
  //   return this.http.post(path, {}).pipe(
  //     map(() => true),
  //     catchError(() => of(false))
  //   );
  // }

  // refer_url_get(): Observable<IHttpResponse> {
  //   const path = `account/refer/url/build`;
  //   return this.http.get(path).pipe(
  //     catchError(this.http.catch())
  //   );
  // }

  // password_token_get(): Observable<IHttpResponse> {
  //   const path = 'account/auth/password/token/retrieve';
  //   return this.http.post(path, {}).pipe(
  //     catchError(this.http.catch()),
  //     tap(this.http.error_msg_handler()),
  //     tap(this.http.msg_handler())
  //   );
  // }

  // send_invitation(body: any): Observable<IHttpResponse> {
  //   const path = `account/refer/url/send`;
  //   return this.http.post(path, body).pipe(
  //     take(1),
  //     catchError(this.http.catch()),
  //     tap(this.http.error_msg_handler()),
  //   );
  // }

  passwordResetByCode(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/password/reset`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      switchMap((res) => {
        if (res?.success) {
          return this.signOut();
        } else {
          return of(res);
        }
      })
    );
  }

  password_recover_code_send(body: any) {
    const path = `${this.#endPoint}/api/session/password/forgot`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  signUp(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/sign-up`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  signUpEmailCheck(body: { email: string }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/email/check`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  emailVerifyByCode(body: {
    email: string;
    code: string;
  }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/email/verify/code`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  emailVerifyByCodeSend(): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/email/verify/code/send`;
    return this.http.post(path, {}).pipe(catchError(this.http.catch()));
  }

  signInUsernameCheck(body: { username: string }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/sign-in/username`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  signInCodeSend(body: { username: string }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/sign-in/code/send`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  signInCodeVerify(body: {
    username: string;
    code: string;
  }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/sign-in/code/verify`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { data } = res;
        if (data) {
          const { sessionToken, refreshToken, account } = data;
          this.accessToken = sessionToken;
          this.refreshToken = refreshToken;
          this.authUser = new Member(account);
          this.isLoggedIn.set(true);
        }
        return res;
      })
    );
  }

  signInPasswordVerify(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/api/session/sign-in`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { data } = res;
        if (data) {
          const { sessionToken, refreshToken, account } = data;
          this.accessToken = sessionToken;
          this.refreshToken = refreshToken;
          this.authUser = new Member(account);
          this.isLoggedIn.set(true);
        }
        return res;
      })
    );
  }

  signOut(): Observable<IHttpResponse<any>> {
    const path = `${this.#endPoint}/api/session/sign-out`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse<any>) => {
        if (res?.success) {
          this.destroy(res.data?.sessionToken);
        }
      })
    );
  }

  destroy(sessionToken: string | null = null): Observable<boolean> {
    this.accessToken = sessionToken;
    this.refreshToken = null;
    // this.authUser = new Member();
    this.isLoggedIn.set(false);
    this.stateService.livemode = true;
    this.authUser = new Member();

    return of(true);
  }

  private access_token_save(token: string | null): Observable<boolean> {
    return this.storageService
      .set(USER_ACCOUNT_ACCESS_TOKEN, token)
      .pipe(take(1));
  }

  private refresh_token_save(token: string | null): Observable<boolean> {
    return this.storageService
      .set(USER_AUTH_REFRESH_TOKEN, token)
      .pipe(take(1));
  }

  private set refreshToken(value: string | null) {
    this.#refreshToken$.next(value);
    this.refresh_token_save(value).pipe(take(1)).subscribe();
  }

  set authUser(value: Member) {
    this.email = value?.data?.email ?? null;
    this.#authUser$.next(value);
  }
}
