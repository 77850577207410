import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocaleSelectorComponent } from "./components/locale-selector/locale-selector.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { DateFnsModule } from "ngx-date-fns";
import { DateFnsLocalePipe } from "./pipes/date-fns-locale.pipe";

@NgModule({
  declarations: [LocaleSelectorComponent, DateFnsLocalePipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    DateFnsModule,

    NzDropDownModule,
    NzButtonModule,
    NzIconModule,
    NzTypographyModule,
    NzDividerModule,
  ],
  exports: [LocaleSelectorComponent, DateFnsLocalePipe],
})
export class TranslateHelperModule {}
