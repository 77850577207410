import { ICart } from "../interfaces";

export const emptyCart: ICart = {
    menuId: 0,
    currency: "EUR",
    items: [],
    itemsCount: 0,
    itemsAmount: 0,
    itemsRelated: [],
    itemsDiscountAmount: 0,
    itemsDiscountableAmount: 0,
    itemsRelatedDiscount: 0,
    itemsSubtotal: 0,
    itemsTax: [],
    itemsToken: "null"
}