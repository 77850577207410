import { DayOfWeek } from "@app/common/interfaces";
import { ICartBuyer } from "../../cart/interfaces";
import { IOrderPayment, IOrderPreset, IOrderService } from "../interfaces";

export const defaultOrderService: IOrderService = {
  schedule: {
    label: null,
    date: new Date().toISOString(),
    time: null,
    asap: 0,
    isReservation: 0,
    dayOfWeek: new Date().getDay() as DayOfWeek,
    timeToWait: 0,
    isLastMinuteOrder: 0,
    timeSlot: "",
  },
  shopId: null,
  zone: undefined,
  zoneId: null,
  type: 1,
};

export const defaultOrderPayment: IOrderPayment = {
  method: null,
};

export const defaultOrderBuyer: ICartBuyer = {
  contact: {
    company: null,
    information: null,
    name: null,
    phoneNumber: null,
    email: null,
  },
  address: {
    zoneLabel: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    postcode: null,
    location: null,
    stateCode: null,
    regionCode: null,
    information: null,
  },
  message: null,
  localeCode: null,
};

export const defaultOrderPreset: IOrderPreset = {
  buyer: defaultOrderBuyer,
  payment: defaultOrderPayment,
  service: defaultOrderService,
  coupons: [],
};
