import { OptionFn } from "../../common/functions";
import { ISelectOption } from "../../common/interfaces";
import { SupportedLocale, SupportedLocaleCode } from "../interfaces";

export const SUPPORTED_LOCALES: SupportedLocale = {
    fr: {
        text: SupportedLocaleCode.fr,
        translate: `locale.${SupportedLocaleCode.fr}`
    },
    zh: {
        text: SupportedLocaleCode.zh,
        translate: `locale.${SupportedLocaleCode.zh}`
    },
    en: {
        text: SupportedLocaleCode.en,
        translate: `locale.${SupportedLocaleCode.en}`
    },
    nl: {
        text: SupportedLocaleCode.nl,
        translate: `locale.${SupportedLocaleCode.nl}`
    },
}

export const SUPPORTED_LOCALE_OPTIONS: ISelectOption[] = OptionFn(SUPPORTED_LOCALES);