import { EventEmitter, Injectable, signal } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  Observable,
  shareReplay,
  take,
  forkJoin,
} from "rxjs";
import { tap, map } from "rxjs/operators";
import { HttpHelperService } from "@app/helpers/http";
import { IHttpResponse } from "@app/helpers/http/interfaces";
import { StorageHelperService } from "@app/helpers/storage";
import { defaultShopNamespace } from "@app/helpers/storage/constants";
import { environment } from "@environments/environment";
import { OrderApiService } from "../order";
import { IShop } from "./interfaces";
import { OrderServiceType } from "../order/interfaces";
import * as qs from "qs";
@Injectable({
  providedIn: "root",
})
export class ShopApiService {
  private coreEndPoint = environment.coreEndPoint;
  shopChange = new EventEmitter<boolean>();

  shop$ = signal<IShop | null>(null);

  #list = new BehaviorSubject<IShop[]>([]);
  list$ = this.#list.asObservable();
  loading$ = signal(false);
  constructor(
    private storageService: StorageHelperService,
    private http: HttpHelperService,
    private orderService: OrderApiService
  ) {
    this.init();
  }

  set list(data: IShop[]) {
    this.#list.next(data);
  }

  initList(fromServer = false): Observable<IShop[]> {
    this.loading$.set(true);
    const method = fromServer
      ? this.getListFromServer<{ count: number; shops: IShop[] }>()
      : this.getLocalList<{ count: number; shops: IShop[] }>();

    return method.pipe(
      take(1),
      tap(() => this.loading$.set(false)),
      map((res) => {
        const { data } = res;

        const list = data?.shops ?? [];
        this.list = list;
        return list;
      })
    );
  }

  init(): Observable<{ shop: IShop | null; list: IShop[] }> {
    return forkJoin({
      list: this.initList(true),
      shop: this.getShop(),
    }).pipe(
      map((res) => {
        const { list, shop } = res;
        if (!shop) {
          return res;
        }

        if (list) {
          const idx = list.findIndex((item) => item.id === shop.id);
          if (idx < 0) {
            this.saveShop(null);
            return { shop: null, list };
          }
        }

        return res;
      })
    );
  }

  saveShop(data: IShop | null): void {
    this.setShop(data)
      .pipe(
        // tap(() => {
        //   if (data) {
        //     return this.orderService.updatePresetServiceShop({ shopId: data.id }).pipe(
        //       tap(res => {
        //         if (res.success) {
        // this.shopChange.emit(data);
        //         }
        //       })
        //     );
        //   } else {
        //     return of();
        //   }
        // }),
        take(1)
      )
      .subscribe();
    this.shop$.set(data);
  }

  getLocalList<T>(): Observable<IHttpResponse<T>> {
    return this.getListFromServer<T>().pipe(shareReplay());
  }

  getListFromServer<T>(): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/shops`;

    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  getSchedules<T>(shopId: number): Observable<IHttpResponse<T>> {
    return this.getScheduleFromServer<T>(shopId).pipe(shareReplay(1));
  }

  getScheduleFromServer<T>(shopId: number): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/shop/${shopId}/schedule`;
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  getDeliveryZones<T>(
    shopId: number,
    body: any = {}
  ): Observable<IHttpResponse<T>> {
    return this.getDeliveryZonesFromServer<T>(shopId, body).pipe(
      shareReplay(1)
    );
  }

  getDeliveryZonesFromServer<T>(
    shopId: number,
    body: any = {}
  ): Observable<IHttpResponse<T>> {
    this.loading$.set(true);
    const query = qs.stringify(body);
    const path = `${this.coreEndPoint}/api/shop/${shopId}/delivery-zone?${query}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading$.set(false))
    );
  }

  getTimetable<T>(
    shopId: number,
    serviceType: OrderServiceType,
    date: string | null
  ): Observable<IHttpResponse<T>> {
    return this.getTimetableFromServer<T>(shopId, serviceType, date).pipe(
      shareReplay(1)
    );
  }

  getTimetableFromServer<T>(
    shopId: number,
    serviceType: OrderServiceType,
    date: string | null
  ): Observable<IHttpResponse<T>> {
    let path = `${this.coreEndPoint}/api/shop/${shopId}/timetable/${serviceType}`;
    if (date) {
      path += `?date=${date}`;
    }
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  getPaymentMethods<T>(): Observable<IHttpResponse<T>> {
    return this.getPaymentMethodsFromServer<T>(this.shop$()?.id ?? 0).pipe(
      shareReplay(1)
    );
  }

  getPaymentMethodsFromServer<T>(shopId: number): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/shop/${shopId}/payment/method`;
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  private getShop(): Observable<any> {
    return this.storageService
      .get(defaultShopNamespace)
      .pipe(tap((data) => this.shop$.set(data)));
  }

  private setShop(data: IShop | null): Observable<any> {
    return this.storageService.set(defaultShopNamespace, data);
  }
}
