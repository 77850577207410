import { ApiStatuses } from "@app/common/constants";
import { ApiStatusCode, IApiStatus } from "../interfaces";

export class ApiStatusModel {
  private _name: string = "Unknown";
  private _status: string = "Unknown";
  private _code: ApiStatusCode = "normal";
  message: string | null = null;
  rbac: any = null;

  constructor(data: IApiStatus | null) {
    this.code = data?.status ?? null;
    this.message = data?.message ?? null;
    // this.rbac = data?.rbac ?? null;
  }

  set name(value: string | null) {
    this._name = value ?? "Unknown";
  }

  get name(): string {
    return this._name;
  }

  set code(code: ApiStatusCode | null) {
    this._code = code ?? "outage";
    this.status = this.code;
  }

  get code(): ApiStatusCode {
    return this._code;
  }

  set status(code: string) {
    const key = code as ApiStatusCode;
    this._status = ApiStatuses[key];
  }

  get status(): string {
    return this._status;
  }
}
