import { Injectable, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { IHttpResponseError } from "@app/helpers/http/interfaces";

@Injectable({
  providedIn: "root",
})
export class OrderAlertService {
  constructor(
    private translateService: TranslateService,
    private modalService: NzModalService
  ) {}

  errorMessage(error: IHttpResponseError): void {
    const name = error.name ? error.name : "usageError";
    const code = error.code ? error.code : "usageError";
    const problems = error.problems ?? [];
    const title = this.translateService.instant(
      "message.error." + name + "." + code
    );
    const message = problems.join("<br />");
    this.modalService.warning({
      nzTitle: title,
      nzContent: `<p class="font-bold">${message}</p>`,
      nzCentered: true,
      nzClosable: false,
    });
  }

  confirming(): NzModalRef {
    const title = this.translateService.instant("order.request.processing");
    const modalRef = this.modalService.create({
      nzTitle: title,
      nzContent: `Loading`,
      nzCentered: true,
      nzClosable: false,
      nzMaskClosable: false,
    });
    return modalRef;
  }

  loading(content: string | TemplateRef<any> | undefined): NzModalRef {
    const modalRef = this.modalService.create({
      nzContent: content,
      nzCentered: true,
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: null,
    });
    return modalRef;
  }
}
