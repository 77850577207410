import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { IHttpResponseError } from "@app/helpers/http/interfaces";

@Injectable({
  providedIn: "root",
})
export class ShopAlertService {
  constructor(
    private translateService: TranslateService,
    private modalService: NzModalService
  ) {}

  errorMessage(error: IHttpResponseError): void {
    const { name, code } = error;
    const problems = error.problems ?? [];
    const title = this.translateService.instant(
      "message.error." + name + "." + code
    );
    const message = problems.join("<br />");
    this.modalService.warning({
      nzTitle: title,
      nzContent: `<p class="font-bold">${message}</p>`,
      nzCentered: true,
      nzClosable: false,
    });
  }

  pickupUnavailable(): void {
    const title = this.translateService.instant("service.warning.title");
    const message = this.translateService.instant("service.pickup.unavailable");
    this.modalService.warning({
      nzTitle: title,
      nzContent: `<p class="font-bold">${message}</p>`,
      nzCentered: true,
      nzClosable: false,
    });
  }
  deliveryUnavailable(): void {
    const title = this.translateService.instant("service.warning.title");
    const message = this.translateService.instant(
      "service.delivery.unavailable"
    );
    this.modalService.warning({
      nzTitle: title,
      nzContent: `<p class="font-bold">${message}</p>`,
      nzCentered: true,
      nzClosable: false,
    });
  }
}
