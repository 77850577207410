import { OptionFn } from "../functions";
import { ISelectOption } from "../interfaces";

export const ApiStatuses = {
    normal: '正常',
    abnormal: '异常',
    maintenance: '维护中',
    outage: '停运',
} as const;

export const ApiStatusOptions: ISelectOption[] = OptionFn(ApiStatuses);
