import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, catchError, Observable, tap } from "rxjs";
import { HttpHelperService } from "@app/helpers/http";
import { IHttpResponse } from "@app/helpers/http/interfaces";
import { environment } from "@environments/environment";
import { AuthCoreService } from "../auth";
import { ICartBuyer } from "../cart/interfaces";
import { IShopScheduleTimetableItem } from "../shop/interfaces";
import {
  defaultOrderBuyer,
  defaultOrderPayment,
  defaultOrderService,
} from "./constants";
import {
  DiscountCoupon,
  IOrderPayment,
  IOrderPreset,
  IOrderSearchDTO,
  IOrderService,
  IOrderSummary,
  OrderServiceType,
} from "./interfaces";
import { StatusCode } from "@app/common/interfaces";

@Injectable({
  providedIn: "root",
})
export class OrderApiService {
  private coreEndPoint = environment.coreEndPoint;
  #servicePreset$ = new BehaviorSubject<IOrderService>(defaultOrderService);
  servicePreset$ = this.#servicePreset$.asObservable();
  #buyerPreset$ = new BehaviorSubject<ICartBuyer>(defaultOrderBuyer);
  buyerPreset$ = this.#buyerPreset$.asObservable();
  #paymentPreset$ = new BehaviorSubject<IOrderPayment>(defaultOrderPayment);
  paymentPreset$ = this.#paymentPreset$.asObservable();
  couponsPreset = signal<DiscountCoupon[]>([]);
  orderSummary$ = signal<IOrderSummary | null>(null);
  loading$ = signal<boolean>(false);

  constructor(
    private http: HttpHelperService,
    private httpClient: HttpClient,
    private authService: AuthCoreService
  ) {}

  set servicePreset(value: IOrderService) {
    this.#servicePreset$.next(value);
  }

  set buyerPreset(value: ICartBuyer) {
    this.#buyerPreset$.next(value);
  }

  set paymentPreset(value: IOrderPayment) {
    this.#paymentPreset$.next(value);
  }

  retrievePreset(): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePreset(data: IOrderPreset): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePresetServiceTime(
    data: IShopScheduleTimetableItem
  ): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset/service/time`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePresetServiceType(data: {
    type: OrderServiceType;
    zoneId?: number;
  }): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset/service/type`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePresetServiceShop(data: { shopId: number }): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset/service/shop`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePresetBuyer(data: ICartBuyer): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset/buyer`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePresetCoupon(codes: string[]): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset/coupon`;
    return this.http.put(path, { codes }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  updatePresetPayment(data: IOrderPayment): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/session/preset/payment`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        if (res.success) {
          const { data } = res;
          const preset: IOrderPreset = data?.preset ?? null;
          const accessToken: string = data?.sessionToken ?? null;

          this.setPreset(preset, accessToken);
        }
      })
    );
  }

  searchOne<T>(data: IOrderSearchDTO): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/order/search`;
    return this.http.post(path, data).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {})
    );
  }

  createOrder(data: any): Observable<IHttpResponse> {
    const path = `${this.coreEndPoint}/api/order`;
    return this.http.post(path, data).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {})
    );
  }

  getOrderByMember<T>(): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/session/orders`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {})
    );
  }

  getOrderSummary(
    data: {
      acceptDeliverySurcharge?: StatusCode;
      couponCodes?: string[];
    } = {}
  ): Observable<IHttpResponse> {
    this.loading$.set(true);
    const path = `${this.coreEndPoint}/api/order/summary`;
    return this.http.post(path, data).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        this.loading$.set(false);
      }),
      tap((res: IHttpResponse) => {
        if (res.data) {
          this.orderSummary$.set(res.data);
        }
      })
    );
  }

  private setPreset(
    preset: IOrderPreset | null,
    accessToken: string | null
  ): void {
    if (preset) {
      if (preset.service) {
        this.servicePreset = preset.service;
      }
      if (preset.buyer) {
        this.buyerPreset = preset.buyer;
      }
      if (preset.payment) {
        this.paymentPreset = preset.payment;
      }
      if (preset.coupons) {
        this.couponsPreset.set(preset.coupons);
      }
    }

    if (accessToken) {
      this.authService.accessToken = accessToken;
    }
  }
}
