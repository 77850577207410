import { Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError, delay, map, Observable, tap } from "rxjs";
import { HttpHelperService } from "@app/helpers/http";
import { IHttpResponse } from "@app/helpers/http/interfaces";
import { environment } from "@environments/environment";
import { AuthCoreService } from "../auth";
import { Member } from "../member/models";
import { IApiStatus } from "./interfaces";
import { ApiStatusModel } from "./models";

@Injectable({
  providedIn: "root",
})
export class ApiStatusService {
  #coreEndpoint: string;
  // #loading$ = new BehaviorSubject<boolean>(false);
  // loading$ = this.#loading$.asObservable();

  isLoading = signal(false);

  private _coreApiStatus$ = new BehaviorSubject<ApiStatusModel>(
    new ApiStatusModel(null)
  );
  coreApiStatus$ = this._coreApiStatus$.asObservable();

  gmapApiLoaded = signal(false);

  #mapApiLoaded$ = new BehaviorSubject<boolean>(false);
  mapApiLoaded$ = this.#mapApiLoaded$.asObservable();

  #hideHeaderOnTop$ = new BehaviorSubject<boolean>(false);
  hideHeaderOnTop$ = this.#hideHeaderOnTop$.asObservable();
  constructor(
    private http: HttpHelperService,
    private authService: AuthCoreService,
    private router: Router
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }

  set coreApiStatus(value: ApiStatusModel) {
    this._coreApiStatus$.next(value);
  }

  set mapApiLoaded(value: boolean) {
    this.#mapApiLoaded$.next(value);
  }

  set hideHeaderOnTop(value: boolean) {
    this.#hideHeaderOnTop$.next(value);
  }

  core_api_status_get(): Observable<ApiStatusModel> {
    this.isLoading.set(true);
    const path = `${this.#coreEndpoint}/api/status`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.isLoading.set(false)),
      map((res: IHttpResponse) => {
        const record = (res.data as IApiStatus) ?? null;
        const status = new ApiStatusModel(record);
        const sessionToken = res?.data?.sessionToken;
        if (sessionToken) {
          this.authService.accessToken = sessionToken;
        }

        const isLoggedIn = res?.data?.isLoggedIn ?? false;
        this.authService.isLoggedIn.set(isLoggedIn);

        const member = res?.data?.member ?? null;
        this.authService.authUser = member ? new Member(member) : new Member();

        this.coreApiStatus = status;

        return status;
      }),

      tap((res) => {
        if (res?.code && ["maintenance", "outage"].includes(res.code)) {
          this.router.navigate(["/redirect/maintenance"]);
        }
      })
    );
  }
}
